import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const FullPageHeader = () => {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    const updateScroll = () => {
      let windowScrollTop = window.pageYOffset / 2;
      pageHeader.current.style.transform = 'translate3d(0,' + windowScrollTop + 'px,0)';
    };

    window.addEventListener('scroll', updateScroll);
    return () => window.removeEventListener('scroll', updateScroll);
  });

  return (
    <div className='page-header header-filter' style={{ display: 'flex', alignItems: 'center' }}>
      <div
        className='page-header-image'
        style={{
          backgroundImage: 'url(' + require('assets/img/landing1-resize.jpg') + ')',
          filter: 'contrast(250%) brightness(20%)'
        }}
        ref={pageHeader}
      />
      <Container>
        <Row>
          <Col className='ml-auto mr-auto text-center' md='8'>
            <img src={require('assets/img/logo/logo-white.png')} alt='privatus-main-logo' />
            <h4 className='description'>YOUR RELIABLE TECHNOLOGY PARTNER</h4>
          </Col>
        </Row>
        <Row style={{ marginTop: 100 }}>
          <Col className='ml-auto mr-auto text-center' md='4'>
            <a href='https://www.rivat.io' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none' }}>
              <div className='hover-card'>
                <h4 className='description' style={{ marginBottom: 0, fontWeight: 800 }}>
                  <b>VISIT OUR WEB3 STUDIO</b>
                </h4>
                <img src={require('assets/img/logo/RIVATLABS.png')} alt='Rivatlabs-logo' />
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FullPageHeader;
