import React, { useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  CardFooter,
  CardHeader
} from 'reactstrap';

/** Import Contact Form Context API */
import { ContactContext } from 'components/Context/ContactProvider';

const FullContactPage = () => {
  const { fullname, setFullname, email, setEmail, message, setMessage, loading, sendMail } = useContext(ContactContext);

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  return (
    <Container>
      <Row>
        <Col className='ml-auto mr-auto' md='6' lg='4'>
          <div className='info info-horizontal'>
            <h2 className='title'>Get in Touch</h2>
            <h4 className='description'>
              Want to find out how we can help? Please do not hesitate to contact us now, we're only a contact form away.
            </h4>
          </div>
          <div className='info info-horizontal'>
            <div className='icon icon-info'>
              <i className='now-ui-icons location_pin'></i>
            </div>
            <div className='description'>
              <h5 className='info-title'>Find us at the office</h5>
              <p className='description'>
                Asoke Road<br></br>
                Sukhumvit, Vathana District, <br></br>
                Bangkok, Thailand
              </p>
            </div>
          </div>
          <div className='info info-horizontal'>
            <div className='icon icon-info'>
              <i className='now-ui-icons tech_mobile'></i>
            </div>
            <div className='description'>
              <h5 className='info-title'>Give us a ring</h5>
              <p className='description'>              
                +66 62 321 722 <br></br>
                Mon - Fri, 09:00-18:00
              </p>
            </div>
          </div>
        </Col>
        <Col className='mr-auto' md='6' lg='4'>
          <Card className='card-signup'>
            <CardHeader>
              <CardTitle className='text-center' tag='h4'>
                Contact Us
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form action='' className='form' method=''>
                <InputGroup className={firstFocus ? 'input-group-focus' : ''}>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText style={{ ...(loading ? { backgroundColor: '#e3e3e3' } : {}) }}>
                      <i className='now-ui-icons users_circle-08' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoComplete='firstname'
                    placeholder='First Name...'
                    type='text'
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                    disabled={loading}
                  />
                </InputGroup>
                <InputGroup className={emailFocus ? 'input-group-focus' : ''}>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText style={{ ...(loading ? { backgroundColor: '#e3e3e3' } : {}) }}>
                      <i className='now-ui-icons ui-1_email-85' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoComplete='email'
                    placeholder='Your Email...'
                    type='text'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => setEmailFocus(true)}
                    onBlur={() => setEmailFocus(false)}
                    disabled={loading}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    id='message'
                    name='message'
                    placeholder='Your Message...'
                    rows='6'
                    type='textarea'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={loading}
                  />
                </InputGroup>
                <CardFooter className='text-center'>
                  <Button className='btn-round btn-privatus-purple' size='lg' onClick={sendMail} disabled={loading}>
                    {loading ? <i class='fa fa-circle-o-notch fa-spin' /> : 'Send Message'}
                  </Button>
                </CardFooter>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FullContactPage;
