import React from 'react';
import FullContactPage from 'components/Contact/FullContactPage';
import TransparentFooter from 'components/Footers/TransparentFooter';

const Contact = () => {
  return (
    <div className='signup-page'>
      <div className='page-header header-filter' filter-color='black'>
        <div
          className='page-header-image'
          style={{
            backgroundImage: 'url(' + require('assets/img/five-bulb.jpg') + ')',
            filter: 'grayscale(100%) brightness(50%)'
          }}
        />
        <div className='content'>
          <FullContactPage />
        </div>
        <TransparentFooter />
      </div>
    </div>
  );
};

export default Contact;
