import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const WhySummaries = () => {
  return (
    <div className='section'>
      <div className='about-description text-center'>
        <div className='features-3'>
          <Container>
            <Row>
              <Col className='mr-auto ml-auto' md='8'>
                <h2 className='title'>Integrated Technology Team</h2>
                <h4 className='description'>
                  We're a highly experienced international team who are ready to take on and deliver your project. Our professional approach and
                  dedicated team will ensure you can achieve your dreams.
                </h4>
              </Col>
            </Row>
            <Row style={{ marginBottom: '60px' }}>
              <Col md='4'>
                <div className='info info-hover'>
                  <div className='icon icon-discover icon-circle'>
                    <i className='now-ui-icons education_paper'></i>
                  </div>
                  <h4 className='info-title'>Experience</h4>
                  <p className='description'>We're an international team with extensive experience in software.</p>
                </div>
              </Col>
              <Col md='4'>
                <div className='info info-hover'>
                  <div className='icon icon-design icon-circle'>
                    <i className='now-ui-icons business_globe'></i>
                  </div>
                  <h4 className='info-title'>Integrated</h4>
                  <p className='description'>We work closely with you to truly understand your business.</p>
                </div>
              </Col>
              <Col md='4'>
                <div className='info info-hover'>
                  <div className='icon icon-build icon-circle'>
                    <i className='now-ui-icons ui-2_settings-90'></i>
                  </div>
                  <h4 className='info-title'>Professional</h4>
                  <p className='description'>We set clear deliverables with a structured process we follow.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default WhySummaries;
