import React from 'react';
import { Row, Col } from 'reactstrap';

const SemiPageHeader = ({ image, text, imageFilter }) => {
  const { header, description } = text;

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 1.5;
        pageHeader.current.style.transform = 'translate3d(0,' + windowScrollTop + 'px,0)';
      };

      window.addEventListener('scroll', updateScroll);

      return () => window.removeEventListener('scroll', updateScroll);
    }
  });

  return (
    <>
      <div className='page-header page-header-small'>
        <div
          className='page-header-image'
          style={{
            backgroundImage: 'url(' + image + ')',
            filter: imageFilter
          }}
          ref={pageHeader}
        />
        <div className='content-center'>
          <Row>
            <Col className='ml-auto mr-auto' md='8'>
              <h1 className='title'>{header}</h1>
              <h4>{description}</h4>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SemiPageHeader;
