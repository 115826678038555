import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavPills from 'components/NavPills/NavPills';

const ServiceSection = ({ text, textRight = false, image, children, content, theme }) => {
  const { header, subHeader } = text;
  const order = textRight ? 1 : 0;

  return (
    <div className='projects-2'>
      <Container>
        <Row>
          <Col className='ml-auto mr-auto' lg={{ size: 6, order }} md={{ size: 8, order }}>
            <h2 className='title'>{header}</h2>
            <h6 className='category'>{subHeader}</h6>
            <h5 className='description' style={{ marginTop: '35px', color: '#363636' }}>
              {children}
            </h5>
            <NavPills content={content} theme={theme}/>
          </Col>
          {window.innerWidth > 768 ? (
            <Col lg='6' md='6' className='align-self-center text-center'>
              <img alt={`${header}-${subHeader}`} src={image} />
            </Col>
          ) : null}
        </Row>
      </Container>
    </div>
  );
};

export default ServiceSection;
