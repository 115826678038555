import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

/** Styles */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/now-ui-kit.scss';

/** Main Components */
import ScrollTransparentNavbar from 'components/Navbars/ScrollTransparentNavbar.jsx';
import Home from 'views/Home/Home.jsx';
import About from 'views/About/About.jsx';
import Services from 'views/Services/Services.jsx';
import Expertise from 'views/Expertise/Expertise.jsx';
import Work from 'views/Work/Work.jsx';
import Contact from 'views/Contact/Contact.jsx';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

/** Contact Context Provider */
import ContactProvider from 'components/Context/ContactProvider';

ReactDOM.render(
  <ContactProvider>
    <Router>
      <ScrollTransparentNavbar />
      <ScrollToTop />
      <Switch>
        <Route exact path='/' render={(props) => <Home {...props} />} />
        <Route exact path='/about' render={(props) => <About {...props} />} />
        <Route exact path='/services' render={(props) => <Services {...props} />} />
        <Route exact path='/expertise' render={(props) => <Expertise {...props} />} />
        <Route exact path='/work' render={(props) => <Work {...props} />} />
        <Route exact path='/contact' render={(props) => <Contact {...props} />} />
        <Redirect to='/' />
      </Switch>
    </Router>
  </ContactProvider>,
  document.getElementById('root')
);
