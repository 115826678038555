import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap';
import content from '../../views/Home/content.js';

const ServiceSummaries = () => {
  const { services } = content;

  return (
    <div className='section'>
      <div className='about-description text-center'>
        <div className='features-3'>
          <Container fluid>
            <Row>
              {services &&
                services.map((service, index) => (
                  <Col key={index} className='px-0' md='6'>
                    <Card
                      className='card-raised card-background card-background-product card-no-shadow'
                      style={{
                        backgroundImage: 'url(' + service.image + ')',
                        backgroundSize: 'auto'
                      }}>
                      <CardBody>
                        <h6 className='category'>{service.category}</h6>
                        <CardTitle>
                          {/* <div className='card-image'>
                          <img alt={service.title} className='img-raised rounded' src={service.logo} style={{ width: '200px', height: '200px' }} />
                        </div> */}
                          <h3>{service.title}</h3>
                        </CardTitle>
                        <p className='card-description'>{service.description}</p>
                        <Button className='btn-round' color='neutral' onClick={e => e.preventDefault()}>
                          {service.buttonText}
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ServiceSummaries;

/*
<Container>
            <Row>
              <Col className='mr-auto ml-auto' md='8'>
                <h2 className='title'>We Build Bespoke Digital Solutions</h2>
                <h4 className='description'>
                  From offering digital transformation consultancy to building and developing enterprise-ready scable solutions, we cover all the
                  technological requirements you may have, with our 4-step approach, <strong>Discover, Design, Develop, Scale.</strong>
                </h4>
                <h4 className='description'>
                  We are a highly experienced team who put our clients at the forefront of our deliverables. We act as an extension to our clients
                  existing business, think of us as your technology partner.
                </h4>
              </Col>
            </Row>
            <Row style={{ marginBottom: '60px' }}>
              <Col md='4'>
                <div className='info info-hover'>
                  <div className='icon icon-discover icon-circle'>
                    <i className='now-ui-icons business_bulb-63'></i>
                  </div>
                  <h4 className='info-title'>Experience</h4>
                  <p className='description'>We're an international team with extensive experience in software.</p>
                </div>
              </Col>
              <Col md='4'>
                <div className='info info-hover'>
                  <div className='icon icon-design icon-circle'>
                    <i className='now-ui-icons design_palette'></i>
                  </div>
                  <h4 className='info-title'>Integrated</h4>
                  <p className='description'>We work closely with you to truly understand your business.</p>
                </div>
              </Col>
              <Col md='4'>
                <div className='info info-hover'>
                  <div className='icon icon-build icon-circle'>
                    <i className='now-ui-icons ui-2_settings-90'></i>
                  </div>
                  <h4 className='info-title'>Professional</h4>
                  <p className='description'>We set clear deliverables with a structured process we follow.</p>
                </div>
              </Col>
            </Row>
          </Container> */
