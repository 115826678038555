import React from 'react';
import { Button, Card, CardBody, CardTitle, Container, Col, Row } from 'reactstrap';
import SemiPageHeader from 'components/Headers/SemiPageHeader';
import headerImage from 'assets/img/office.jpg';
import content from './content.js';

const Work = () => {
  const { industries, clients } = content;

  return (
    <div className='wrapper'>
      <SemiPageHeader
        image={headerImage}
        text={{
          header: 'Our Clients',
          description: 'Our clients will tell you our story',
          description2: 'We’ve successfully completed many projects and our plethora of clients can back up our claims of delivering!'
        }}
        imageFilter={'brightness(50%)'}
      />
      <div className='cd-section' id='project'>
        <Container fluid>
          <Row>
            <Col className='ml-auto mr-auto text-center' md='8'>
              <div className='features-3'>
                <h3 className='title'>
                  We’ve successfully completed many projects and our plethora of clients can back up our claims of delivering!
                </h3>
                <h4>Our clients industries include:</h4>
                <br />
                <Row className='justify-content-center'>
                  {industries &&
                    industries.map((item, index) => (
                      <Col md='2' key={index}>
                        <p>{item}</p>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            {clients &&
              clients.map((client, index) => (
                <Col key={index} className='px-0' md='6'>
                  <Card
                    className='card-raised card-background card-background-product card-no-shadow'
                    style={{
                      backgroundImage: 'url(' + client.image + ')',
                      backgroundSize: 'auto'
                    }}>
                    <CardBody>
                      <h6 className='category'>{client.category}</h6>
                      <CardTitle>
                        {/* <div className='card-image'>
                          <img alt={client.title} className='img-raised rounded' src={client.logo} style={{ width: '200px', height: '200px' }} />
                        </div> */}
                        <h3>{client.title}</h3>
                      </CardTitle>
                      <p className='card-description'>{client.description}</p>
                      <Button className='btn-round' color='neutral' onClick={e => e.preventDefault()}>
                        <i className='now-ui-icons ui-1_send'></i> {client.buttonText}
                      </Button>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Work;
