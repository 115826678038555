import React from 'react';
import SemiPageHeader from 'components/Headers/SemiPageHeader';
import ServiceSection from 'components/Sections/ServiceSection';
import headerImage from 'assets/img/mirror-building-resize.jpg';
import discoverImage from 'assets/img/discover.jpg';
import designImage from 'assets/img/design-2.jpg';
import developImage from 'assets/img/develop.jpg';
import scaleImage from 'assets/img/scale.jpg';
import TransaparentFooter from 'components/Footers/TransparentFooter';

const discoverContent = [
  {
    label: 'Research',
    description: ['Market Research', 'Competitor Analysis', 'Requirements Capture'],
    icon: 'fas fa-search'
  },
  {
    label: 'Product Strategy',
    description: ['Product Alignment', 'Roadmapping', 'User Stories'],
    icon: 'fas fa-people-carry'
  },
  {
    label: 'Consultancy',
    description: ['Stakeholder Interviews', 'Knowledge Sessions', 'Design Thinking'],
    icon: 'fas fa-chalkboard-teacher'
  },
  {
    label: 'Futureproof',
    description: ['Stakeholder Interviews', 'Knowledge Sessions', 'Design Thinking'],
    icon: 'fas fa-chalkboard-teacher'
  }
];

const designContent = [
  {
    label: 'Visual Design',
    description: ['Brand Identity', 'UI Wireframing', 'Styling Guides'],
    icon: 'far fa-edit'
  },
  {
    label: 'Experience & Interaction',
    description: ['UX Design', 'User Flow', 'System Design'],
    icon: 'far fa-sticky-note'
  },
  {
    label: 'Prototyping',
    description: ['Sitemap Design', 'Product Testing', 'Focus Groups'],
    icon: 'far fa-object-group'
  },
  {
    label: 'Service Design',
    description: ['UX Design', 'User Flow', 'System Design'],
    icon: 'far fa-sticky-note'
  }
];

const developContent = [
  {
    label: 'Applications',
    description: ['React js & Node js', 'Latest JS Frameworks', 'Dashboards & Ecommerce'],
    icon: 'fas fa-laptop-code'
  },
  {
    label: 'Infrastructure',
    description: ['iOS & Android', 'React Native', 'Scrum Adopted Workflow'],
    icon: 'fas fa-mobile-alt'
  },
  {
    label: 'RESTful APIs',
    description: ['Cloud-based Architectures', 'API Integration', 'Blockchain & AI'],
    icon: 'fas fa-network-wired'
  },
  {
    label: 'Deep-tech Solution',
    description: ['Cloud-based Architectures', 'API Integration', 'Blockchain & AI'],
    icon: 'fas fa-network-wired'
  }
];

const scaleContent = [
  {
    label: 'End-to-end Support',
    description: ['SLA’s & Playbook', 'Maintenance', 'Handover & Training'],
    icon: 'fas fa-headset'
  },
  {
    label: 'DevOps',
    description: ['CICD', 'Quality Assurance', 'Unit & Integration Testing'],
    icon: 'fas fa-code-branch'
  },
  {
    label: 'Optimisation',
    description: ['Data Analytics', 'Reporting Tools', 'A/B Testing'],
    icon: 'fas fa-tools'
  },
  {
    label: 'Growth',
    description: ['Data Analytics', 'Reporting Tools', 'A/B Testing'],
    icon: 'fas fa-tools'
  }
];

const Services = () => {
  React.useEffect(() => {
    document.body.classList.add('sections-page');

    const href = window.location.href.substring(window.location.href.lastIndexOf('#/') + 2);
    const hrefId = href.substring(href.lastIndexOf('#') + 1);

    if (href.lastIndexOf('#') > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
    return () => document.body.classList.remove('sections-page');
  });

  return (
    <>
      <div className='wrapper'>
        <SemiPageHeader
          image={headerImage}
          text={{
            header: 'Our Services',
            description: 'Effective process for delivering quality.'
          }}
          imageFilter={'brightness(50%) contrast(150%) grayscale(30%)'}
        />
        <div className='cd-section' id='discover'>
          <ServiceSection
            text={{ header: 'Discover', subHeader: 'Understanding your business & objectives' }}
            image={discoverImage}
            content={discoverContent}
            theme='privatus-discover'>
            By understanding your customers and their requirements we’ll find the best way to create an enjoyable experience for your users whilst
            also looking to increase revenue and return.
          </ServiceSection>
        </div>
        <div className='separator-line bg-design' />
        <div className='cd-section' id='design'>
          <ServiceSection
            textRight
            text={{ header: 'Design', subHeader: 'Beautifully designed digital products' }}
            image={designImage}
            content={designContent}
            theme='privatus-design'>
            For us to provide an experience your customers won’t forget, we must start by truly understanding the real needs and the best way in which
            they can interact with your software.
            <br />
            <br />
            Our design process provides us with the tools required to confidently and efficiently build the end solution.
          </ServiceSection>
        </div>
        <div className='separator-line bg-develop' />
        <div className='cd-section' id='develop'>
          <ServiceSection
            text={{ header: 'Develop', subHeader: 'Robust futureproof digital solutions' }}
            image={developImage}
            content={developContent}
            theme='privatus-develop'>
            Being able to react to both customer and market shifts is extremely important, our development team works in an agile way, providing
            releases every sprint which can mutate to meet demands.
            <br />
            <br />
            Our build process is the engine that powers everything else. We specialise with our effective approach that provides results beyond our
            client’s expectations.
          </ServiceSection>
        </div>
        <div className='separator-line bg-scale' />
        <div className='cd-section' id='scale'>
          <ServiceSection
            textRight
            text={{ header: 'Scale', subHeader: 'Leveraging the latest technologies' }}
            image={scaleImage}
            content={scaleContent}
            theme='privatus-scale'>
            Only building the application layer isn’t enough in this fast-paced environment we are in nowadays. It’s just as important to continue
            building for the future - and whatever changes it might bring.
            <br />
            <br />
            Scaling is important to continue to keep up with the demands and leverage the tools that are available to help.
          </ServiceSection>
        </div>
      </div>
      <TransaparentFooter />
    </>
  );
};

export default Services;
