import React from 'react';
import { Container } from 'reactstrap';

const TransaparentFooter = () => {
  return (
    <>
      <footer className='footer'>
        <Container>
          {/* <nav>
            <ul>
              <li>
                <a href='' target='_blank'>
                  Privatus
                </a>
              </li>
              <li>
                <a href='' target='_blank'>
                  About Us
                </a>
              </li>
              <li>
                <a href='' target='_blank'>
                  Blog
                </a>
              </li>
            </ul>
          </nav> */}
          <div className='copyright' id='copyright'>
            © {new Date().getFullYear()}, Designed and Coded by Privatus Technologies.
          </div>
        </Container>
      </footer>
    </>
  );
};

export default TransaparentFooter;
