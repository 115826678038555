const content = {
  industries: ['Ecommerce & Retail', 'Dashboards', 'Booking Platforms', 'Startup Applications', 'Financial Services'],
  clients: [
    {
      title: 'Issara',
      image: require('assets/img/clients/issara-residence.jpg'),
      logo: require('assets/img/clients/logos/logo-issara-winery.png'),
      category: 'Real Estate',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'HON',
      image: require('assets/img/clients/hon.jpg'),
      logo: require('assets/img/clients/logos/logo-hon-meruto.jpeg'),
      category: 'Restaurant',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Morson Group',
      image: require('assets/img/clients/morson-group.jpg'),
      // logo: require('assets/img/clients/LOGO.jpg'),
      category: 'Industry',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Dolphin Plaza',
      image: require('assets/img/clients/dolphin-plaza.jpg'),
      // logo: require('assets/img/clients/LOGO.jpg'),
      category: 'Department Store',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'FPT Telecom',
      image: require('assets/img/clients/fpt-telecom.jpg'),
      logo: require('assets/img/clients/logos/logo-fpt-software.png'),
      category: 'Technology',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'GreyTv',
      image: require('assets/img/clients/greytv.jpg'),
      // logo: require('assets/img/clients/LOGO.jpg'),
      category: 'Advertisement',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Ryoma Golf',
      image: require('assets/img/clients/ryoma-golf.jpg'),
      logo: require('assets/img/clients/logos/logo-ryoma-golf.png'),
      category: 'Sport',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Rubbish Collective',
      image: require('assets/img/clients/rubbish-collective.jpg'),
      // logo: require('assets/img/clients/LOGO.jpg'),
      category: 'Waste',
      description: '',
      buttonText: 'Read more'
    },

    {
      title: 'Into Latin America',
      image: require('assets/img/clients/latin-america.jpg'),
      // logo: require('assets/img/clients/LOGO.jpg'),
      category: 'Charity',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Lifestyle Natural Health',
      image: require('assets/img/clients/lifestyle-natural-health.jpg'),
      // logo: require('assets/img/clients/LOGO.jpg'),
      category: 'Health & Beauty',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Adenergy',
      image: require('assets/img/clients/adenergy.jpg'),
      logo: require('assets/img/clients/logos/logo-adenergy.png'),
      category: 'Energy',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Beam',
      image: require('assets/img/clients/beam.jpg'),
      logo: require('assets/img/clients/logos/logo-beam.png'),
      category: 'Entertainment',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Tutor Troops',
      image: require('assets/img/clients/tutor-troops.jpg'),
      // logo: require('assets/img/clients/LOGO.jpg'),
      category: 'Education',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: '10X Wealth Management',
      image: require('assets/img/clients/10x-wealth.jpg'),
      logo: require('assets/img/clients/logos/logo-10x-wm.png'),
      category: 'Financial',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Lazy Panda',
      image: require('assets/img/clients/lazy-panda.jpg'),
      logo: require('assets/img/clients/lazy-panda-logo.png'),
      category: 'Restaurant',
      description: '',
      buttonText: 'Read more'
    },
    {
      title: 'Pacific Project Management',
      image: require('assets/img/clients/pacific-project-management.jpg'),
      logo: require('assets/img/clients/logos/logo-pacific-project-management.png'),
      category: 'Industry',
      description: '',
      buttonText: 'Read more'
    }
  ]
};

export default content;
