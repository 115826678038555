import React from 'react';
import { withRouter } from 'react-router-dom';

// reactstrap components
import { Button, Container, Row, Col } from 'reactstrap';

function AboutSummaries({ history }) {
  return (
    <>
      <div className='section section-about-us'>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto text-center' md='8'>
              <h2 className='title'>We Build Bespoke Digital Solutions</h2>
              <h4 className='description'>
                From offering digital transformation consultancy to building and developing enterprise-ready scalable solutions, we cover all the
                technological requirements you may have, with our 4-step approach, <strong>Discover, Design, Develop, Scale.</strong>
              </h4>
            </Col>
          </Row>
          <div className='separator separator-info'></div>
          <div className='section-story-overview'>
            <Row>
              <Col md='6'>
                <div
                  className='image-container image-left'
                  style={{
                    backgroundImage: 'url(' + require('assets/img/bg38.jpg') + ')'
                  }}>
                  <p className='blockquote blockquote-info'>
                    "The team at Privatus delivered such professional service with a quality final delivery. I didn't think I'd find a software agency
                    that could build such a solid product." <br></br>
                    <br></br>
                    <small>-NOAA</small>
                  </p>
                </div>
                <div
                  className='image-container image-left-bottom'
                  style={{
                    backgroundImage: 'url(' + require('assets/img/bg24.jpg') + ')'
                  }}></div>
              </Col>
              <Col md='5'>
                <div
                  className='image-container image-right'
                  style={{
                    backgroundImage: 'url(' + require('assets/img/bg39.jpg') + ')'
                  }}></div>
                <h3>We're a Bangkok-based Software Development Agency that specialises in building agile products.</h3>
                <p>
                  Our highly experienced team put our clients at the forefront of our deliverables. We act as an extension to our clients existing
                  business, think of us as your technology partner rather than as a software agency.
                </p>
                <p>
                  We ensure that we keep our clients involved with our product development, we also provide a transparent pricing model meaning
                  clients trust our approach to any project and any guidance we provide.
                </p>
                <p>Reach out to us today to find out how we can help your business reach it's dreams.</p>
                <Button color='privatus-turq' size='lg' onClick={() => history.push('/contact')}>
                  Contact Us
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default withRouter(AboutSummaries);
