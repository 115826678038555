import React from 'react';
import FullPageHeader from 'components/Headers/FullPageHeader';
import ServiceSummaries from 'components/Summaries/ServiceSummaries';
import AboutSummaries from 'components/Summaries/AboutSummaries';
import TransaparentFooter from 'components/Footers/TransparentFooter';
import ProjectLogos from 'components/Summaries/ProjectLogos';
import WhySummaries from 'components/Summaries/WhySummaries';
import ContactUs from 'components/Contact/ContactUs';
import ServiceIcons from 'components/Summaries/ServiceIcons';

const Home = () => {
  return (
    <div className='wrapper'>
      <FullPageHeader />
      <AboutSummaries />
      <ServiceIcons />
      <ServiceSummaries />
      <ProjectLogos />
      <WhySummaries />
      <ContactUs />
      <TransaparentFooter />
    </div>
  );
};

export default Home;
