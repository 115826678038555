import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import content from '../../views/Home/content.js';

const ServiceIcons = () => {
  const { serviceDetails } = content;

  return (
    <>
      <div className='cd-section' id='features'>
        <Container>
          <Row>
            {serviceDetails &&
              serviceDetails.map((serviceDetail, index) => (
                <Col key={index} md='3'>
                  <div className='info info-hover'>
                    <div className={'icon icon-circle ' + serviceDetail.iconStyle}>
                      <i className={'fas ' + serviceDetail.icon}></i>
                    </div>
                    <h4 className='info-title'>{serviceDetail.title}</h4>
                    <p className='description'>{serviceDetail.description}</p>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ServiceIcons;
