import React, { useContext } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap';

/** Import Contact Form Context API */
import { ContactContext } from 'components/Context/ContactProvider';

function ContactUs() {
  const { fullname, setFullname, email, setEmail, message, setMessage, loading, sendMail } = useContext(ContactContext);

  const [first1Focus, setFirst1Focus] = React.useState(false);
  const [email1Focus, setEmail1Focus] = React.useState(false);

  return (
    <>
      <div className='cd-section' id='contact-us'>
        <div
          className='contactus-1 section-image'
          style={{
            backgroundImage: 'url(' + require('assets/img/contact1.jpg') + ')'
          }}>
          <Container>
            <Row>
              <Col md='5'>
                <h2 className='title'>Get in Touch</h2>
                <h4 className='description'>Want to find out how we can help? Please do not hesitate to contact us now.</h4>
                <div className='info info-horizontal'>
                  <div className='icon icon-info'>
                    <i className='now-ui-icons location_pin'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Find us at the office</h4>
                    <p className='description'>
                      Asoke Road<br></br>
                      Sukhumvit, Vathana District, <br></br>
                      Bangkok, Thailand
                    </p>
                  </div>
                </div>
                <div className='info info-horizontal'>
                  <div className='icon icon-info'>
                    <i className='now-ui-icons tech_mobile'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Give us a ring</h4>
                    <p className='description'>
                      +66 62 321 722 <br></br>
                      Mon - Fri, 09:00-18:00
                    </p>
                  </div>
                </div>
              </Col>
              <Col className='ml-auto mr-auto' md='5'>
                <Card className='card-signup'>
                  <Form id='contact-form1' method='post' role='form'>
                    <CardHeader>
                      <CardTitle className='text-center' tag='h4'>
                        Contact Us
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className='pr-2' md='12'>
                          <label>Full Name</label>
                          <InputGroup className={first1Focus ? 'input-group-focus' : ''}>
                            <InputGroupAddon addonType='prepend'>
                              <InputGroupText style={{ ...(loading ? { backgroundColor: '#e3e3e3' } : {}) }}>
                                <i className='now-ui-icons users_circle-08' />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              aria-label='Full Name...'
                              autoComplete='given-name'
                              placeholder='Full Name...'
                              type='text'
                              value={fullname}
                              onChange={(e) => setFullname(e.target.value)}
                              onFocus={() => setFirst1Focus(true)}
                              onBlur={() => setFirst1Focus(false)}
                              disabled={loading}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <label>Email Address</label>
                        <InputGroup className={email1Focus ? 'input-group-focus' : ''}>
                          <InputGroupAddon addonType='prepend'>
                            <InputGroupText style={{ ...(loading ? { backgroundColor: '#e3e3e3' } : {}) }}>
                              <i className='now-ui-icons ui-1_email-85' />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete='email'
                            placeholder='Your Email...'
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={() => setEmail1Focus(true)}
                            onBlur={() => setEmail1Focus(false)}
                            disabled={loading}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          id='message'
                          name='message'
                          rows='6'
                          type='textarea'
                          placeholder='Your Message...'
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          disabled={loading}
                        />
                      </FormGroup>
                      <Row>
                        <Col md={{ size: 6, offset: 6 }}>
                          <Button className='btn-round pull-right btn-privatus-purple' onClick={sendMail} disabled={loading}>
                            {loading ? <i class='fa fa-circle-o-notch fa-spin' /> : 'Send Message'}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
