import React, { useState, createContext } from 'react';
import emailjs from 'emailjs-com';

export const ContactContext = createContext(null);

const ContactProvider = ({ children }) => {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const sendMail = async () => {
    setLoading(true);

    const templateParams = {
      fromName: fullname,
      fromEmail: email,
      message: message
    };

    const userID = process.env.REACT_APP_MAILJS_USER_ID;
    const serviceId = process.env.REACT_APP_MAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_MAILJS_TEMPLATE_ID;

    try {
      await emailjs.send(serviceId, templateID, templateParams, userID);
    } catch (error) {
      console.log(error.text);
    } finally {
      setLoading(false);
      setFullname('');
      setEmail('');
      setMessage('');
    }
  };

  return (
    <ContactContext.Provider value={{ fullname, setFullname, email, setEmail, message, setMessage, loading, sendMail }}>
      {children}
    </ContactContext.Provider>
  );
};

export default ContactProvider;
