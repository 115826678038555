import React from 'react';
import { Nav, NavLink, NavItem, TabContent, TabPane, Row, Col } from 'reactstrap';

const NavPills = ({ content, theme }) => {
  const [iconVerticalTabs, setIconVerticalTabs] = React.useState('0');

  return (
    <Row>
      <Col lg='6' md='6'>
        <Nav className={`nav-pills-${theme} nav-pills-icons flex-column`} pills role='tablist' style={{ paddingTop: '30px' }}>
          {content &&
            content.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  className={iconVerticalTabs === String(index) ? 'active' : ''}
                  onClick={e => {
                    e.preventDefault();
                    setIconVerticalTabs(String(index));
                  }}>
                  <i className={item.icon}></i>
                  {item.label}
                </NavLink>
              </NavItem>
            ))}
        </Nav>
      </Col>
      <Col md='6'>
        <TabContent activeTab={'iconVerticalTabs' + iconVerticalTabs} style={{ paddingTop: '40px' }}>
          {content &&
            content.map((item, index) => (
              <TabPane key={index} tabId={`iconVerticalTabs${index}`}>
                <ul>
                  {item.description.map((listItem, listIndex) => (
                    <li key={listIndex}>{listItem}</li>
                  ))}
                </ul>
              </TabPane>
            ))}
        </TabContent>
      </Col>
    </Row>
  );
};

export default NavPills;
