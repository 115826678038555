import React from 'react';

// reactstrap components
import { Container, Row, Col, Button } from 'reactstrap';

const ProjectLogos = () => {
  return (
    <>
      <div className='section section-project-logos'>
        <Container fluid>
          <Row>
            <Col className='ml-auto mr-auto text-center' md='8'>
              <h2 className='title'>Clients we've delivered for</h2>
            </Col>
          </Row>
          <Row xs='2' md='4'>
            <Col className='text-center'>
              <img className='homepage-logo' src={require('assets/img/clients/logos/logo-fpt-software.png')} alt='' />
            </Col>
            <Col className='text-center'>
              <img className='homepage-logo' src={require('assets/img/clients/logos/logo-issara-winery.png')} alt='' />
            </Col>
            <Col className='text-center'>
              <img className='homepage-logo' src={require('assets/img/clients/logos/logo-hon-meruto.jpeg')} alt='' />
            </Col>
            <Col className='text-center'>
              <img className='homepage-logo' src={require('assets/img/clients/logos/logo-ryoma-golf.png')} alt='' />
            </Col>
          </Row>
          <Row xs='2' md='4'>
            <Col className='text-center'>
              <img className='homepage-logo' src={require('assets/img/clients/logos/logo-adenergy.png')} alt='' />
            </Col>
            <Col className='text-center'>
              <img className='homepage-logo' src={require('assets/img/clients/logos/logo-10x-wm.png')} alt='' />
            </Col>
            <Col className='text-center'>
              <img className='homepage-logo' src={require('assets/img/clients/logos/logo-beam.png')} alt='' />
            </Col>
            <Col className='text-center'>
              <img className='homepage-logo' src={require('assets/img/clients/logos/logo-pacific-project-management.png')} alt='' />
            </Col>
          </Row>
          <Row>
            <Col className='ml-auto mr-auto text-center' md='8'>
              <Button color='privatus-purple' size='lg' to='/work'>
                View All
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProjectLogos;
