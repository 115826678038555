const content = {
  services: [
    {
      title: 'Understand your technology',
      image: require('assets/img/services/service-discover.jpg'),
      category: 'Discover',
      description: 'Let us help you understand where you currently reside in the technology space, and then form a strategy for the future.',
      buttonText: 'Read more',
    },
    {
      title: 'Meet your customer requirements',
      image: require('assets/img/services/service-design.jpg'),
      category: 'Design',
      description: 'By using our UI/UX team, we can help you understand what your customer requirements are and how to increase sales.',
      buttonText: 'Read more',
    },
    {
      title: 'Build your robust tailored solution',
      image: require('assets/img/services/service-develop.jpg'),
      category: 'Develop',
      description: 'We have a highly skilled team of developers to build a solid back-end and beautiful front-end for your application.',
      buttonText: 'Read more',
    },
    {
      title: 'Leverage the latest technologies',
      image: require('assets/img/services/service-scale.jpg'),
      category: 'Scale',
      description: "We can help ensure your product can deal with the issues of scalability, we'll also implement the latest technologies.",
      buttonText: 'Read more',
    },
  ],
  serviceDetails: [
    {
      title: 'UI/UX',
      icon: 'fa-pencil-ruler',
      iconStyle: 'icon-discover',
      description: 'Aestheticall beautiful designs that match user behaviour',
    },
    {
      title: 'Web Applications',
      icon: 'fa-code',
      iconStyle: 'icon-design',
      description: 'Powerful web-application development using javascript',
    },
    {
      title: 'Mobile Applications',
      icon: 'fa-mobile-alt',
      iconStyle: 'icon-build',
      description: 'Android & iOS mobile applications for download',
    },
    {
      title: 'Web Development',
      icon: 'fa-desktop',
      iconStyle: 'icon-scale',
      description: 'Professional websites to showcase to your customers',
    },
    {
      title: 'Blockchain',
      icon: 'fa-link',
      iconStyle: 'icon-scale',
      description: 'Public & Private blochains for decentralised aspects',
    },
    {
      title: 'Data & AI',
      icon: 'fa-brain',
      iconStyle: 'icon-build',
      description: 'Make use of your data to leverage opportunities',
    },
    {
      title: 'DevOps',
      icon: 'fa-cogs',
      iconStyle: 'icon-design',
      description: 'CICD & other pipelines to ensure uptime',
    },
    {
      title: 'Maintenance',
      icon: 'fa-tools',
      iconStyle: 'icon-discover',
      description: 'Support your applications and maintenence',
    },
  ],
};

export default content;
