import React, { useContext } from 'react';
import { Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, FormGroup } from 'reactstrap';

/** Import Contact Form Context API */
import { ContactContext } from 'components/Context/ContactProvider';

const Contact = () => {
  const { fullname, setFullname, email, setEmail, message, setMessage, loading, sendMail } = useContext(ContactContext);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);

  return (
    <div className='about-contact'>
      <Container>
        <Row>
          <Col className='mr-auto ml-auto' md='8'>
            <h2 className='text-center title'>Want to work with us?</h2>
            <h4 className='text-center description'>
              Want to find out how we can help? Please do not hesitate to contact us now, we're only a contact form away.
            </h4>
            <Form className='contact-form'>
              <Row>
                <Col md='6'>
                  <label>Full Name</label>
                  <InputGroup className={firstFocus ? 'input-group-focus' : ''}>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText style={{ ...(loading ? { backgroundColor: '#e3e3e3' } : {}) }}>
                        <i className='now-ui-icons users_circle-08' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      autoComplete='fullname'
                      placeholder='Full Name...'
                      type='text'
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                      disabled={loading}
                    />
                  </InputGroup>
                </Col>
                <Col md='6'>
                  <label>Your email</label>
                  <InputGroup className={emailFocus ? 'input-group-focus' : ''}>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText style={{ ...(loading ? { backgroundColor: '#e3e3e3' } : {}) }}>
                        <i className='now-ui-icons ui-1_email-85' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      autoComplete='email'
                      placeholder='Your Email...'
                      type='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                      disabled={loading}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Input
                      id='message'
                      name='message'
                      rows='12'
                      type='textarea'
                      placeholder='Your Message...'
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      disabled={loading}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className='ml-auto mr-auto text-center' md='4'>
                  <Button className='btn-round mt-4 btn-privatus-purple' size='lg' onClick={sendMail} disabled={loading}>
                    {loading ? <i class='fa fa-circle-o-notch fa-spin' /> : "Let's talk"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
