import React from 'react';
import { Container } from 'reactstrap';
import TransaparentFooter from 'components/Footers/TransparentFooter';

const BasicDisplay = ({ text }) => {
  return (
    <div className='wrapper'>
      <div className='page-header header-filter' style={{ display: 'flex', alignItems: 'center', backgroundColor: '#4d4d4d' }}>
        <Container>
          <h1 className='title'>{text}</h1>
        </Container>
        <TransaparentFooter/>
      </div>
    </div>
  );
};

export default BasicDisplay;
