import React from 'react';
import { Badge, Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import Contact from 'components/Contact/Contact';
import SemiPageHeader from 'components/Headers/SemiPageHeader';
import headerImage from 'assets/img/building-resize.jpg';
import TransaparentFooter from 'components/Footers/TransparentFooter';

const About = () => {
  return (
    <div className='wrapper'>
      <SemiPageHeader
        image={headerImage}
        text={{
          header: 'About',
          description: 'We work with you to help you realise your vision and create innovative products to enhance your business.'
        }}
        imageFilter={'brightness(50%)'}
      />
      <div className='section about-us'>
        <div className='projects-5'>
          <Container>
            <Row>
              <Col className='ml-auto mr-auto text-center' md='8'>
                <h2 className='title'>Who are we</h2>
                <h4 className='description'>We are passionate technology enthusiasts from a diverse range of backgrounds.</h4>
                <div className='section-space'></div>
              </Col>
            </Row>
            <Row>
              <Col className='ml-auto' md='5'>
                <Card
                  className='card-background card-background-product card-raised'
                  style={{
                    backgroundImage: 'url(' + require('assets/img/bulb-dark-resize.jpg') + ')'
                  }}>
                  <CardBody>
                    <CardTitle tag='h2'>Challenge Ideas</CardTitle>
                    <p className='card-description'>
                      We love discussing and debating ideas, discussion leads to innovation and we truly believe that everyone should be listened to and heard.
                    </p>
                    <Badge className='badge-neutral'>Lorem</Badge>
                  </CardBody>
                </Card>
              </Col>
              <Col className='mr-auto' md='5'>
                <div className='info info-horizontal'>
                  <div className='icon icon-discover'>
                    <i className='now-ui-icons ui-2_chat-round'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Communication</h4>
                    <p className='description'>
                      We try to make our work enjoyable, promoting an open dialogue of discussion for any topic.
                    </p>
                  </div>
                </div>
                <div className='info info-horizontal'>
                  <div className='icon icon-discover'>
                    <i className='now-ui-icons business_chart-pie-36'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Logical</h4>
                    <p className='description'>We try to use logical explanations wherever necessary, we're open-minded.</p>
                  </div>
                </div>
                <div className='info info-horizontal'>
                  <div className='icon icon-discover'>
                    <i className='now-ui-icons design-2_ruler-pencil'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Creative</h4>
                    <p className='description'>
                      Do you have a product idea? We have specific flows for promoting creativity and ensure that everyone gets listened to.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col className='ml-auto mt-5' md='5'>
                <div className='info info-horizontal'>
                  <div className='icon icon-scale'>
                    <i className='now-ui-icons users_single-02'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>People-first</h4>
                    <p className='description'>Ultimately we have a human-first approach, we want people to enjoy working and balance it around their lifestyles.</p>
                  </div>
                </div>
                <div className='info info-horizontal'>
                  <div className='icon icon-scale'>
                    <i className='now-ui-icons business_chart-bar-32'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Growth</h4>
                    <p className='description'>By continiously reviewing our growth and performance we're able to help all team members grow with us</p>
                  </div>
                </div>
                <div className='info info-horizontal'>
                  <div className='icon icon-scale'>
                    <i className='now-ui-icons arrows-1_refresh-69'></i>
                  </div>
                  <div className='description'>
                    <h4 className='info-title'>Process-Driven</h4>
                    <p className='description'>A well organised team is a productive team, we try to have clear processes where possible</p>
                  </div>
                </div>
              </Col>
              <Col className='mr-auto' md='5'>
                <Card
                  className='card-background card-background-product card-raised'
                  style={{
                    backgroundImage: 'url(' + require('assets/img/mining-rig-resize.jpg') + ')'
                  }}>
                  <CardBody>
                    <CardTitle tag='h2'>Technology Nerds</CardTitle>
                    <p className='card-description text-white'>
                      We love everything about technology, we're highly passionate about learning new ideas and new paradigm shifts within the tech industry.
                    </p>
                    <Badge className='badge-neutral'>Trello</Badge>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <hr />
        <Contact />
      </div>
      <TransaparentFooter />
    </div>
  );
};

export default About;
